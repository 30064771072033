import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AsyncPipe} from '@angular/common';
import {CoreModule} from '@core/core.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {EntityDropdown} from '@core/components/entity-dropdown/entity-dropdown.component';
import {SectorService} from '@core/services/global/sector.service';
import {SectorCreateRequest, SectorModel} from '@core/models/sector.model';
import {FormsModule} from '@angular/forms';
import {DialogService} from '@core/services/global/dialog.service';
import {switchMap} from 'rxjs';
import {SharedModule} from '@shared/shared.module';

@Component({
    selector: 'omt-sector-dropdown',
    standalone: true,
    imports: [
        AsyncPipe,
        CoreModule,
        NgSelectModule,
        FormsModule,
        SharedModule
    ],
    templateUrl: './sector-dropdown.component.html',
    styleUrl: './sector-dropdown.component.scss'
})
export class SectorDropdownComponent extends EntityDropdown {
    @Input() selectedSector: SectorModel;
    @Output() selectedSectorChange = new EventEmitter<SectorModel>();

    sectors$ = this.sectorService.getSectors();

    constructor(private readonly sectorService: SectorService,
                private readonly dialogService: DialogService) {
        super();
    }

    openDialog(): void {
        const dialogRef = this.dialogService.textEditDialog('Neue Branche erstellen', '', '');

        this.subs.sink = dialogRef.componentInstance.saveClicked
            .pipe(switchMap((value) => this.sectorService.createSector(new SectorCreateRequest(value))))
            .subscribe({
                    next: (newSubSector) => {
                        this.sectors$ = this.sectorService.getSectors();
                        this.selectedSector = newSubSector;
                        this.selectedSectorChange.emit(this.selectedSector);
                    }
                }
        );
    }
}
