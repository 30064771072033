import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    CustomerReferencesDialogComponent
} from '@customer/components/customer-detail/hourly-rate/customer-references-dialog/customer-references-dialog.component';
import {DialogHostModule} from '@libs/ui/src';
import {FlexModule} from '@ngbracket/ngx-layout';
import {FormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';


@NgModule({
    declarations: [
        CustomerReferencesDialogComponent],
    imports: [
        CommonModule,
        DialogHostModule,
        FlexModule,
        FormsModule,
        MatButtonModule
    ]
})
export class CustomerReferencesDialogModule {
}
