import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {FlexModule} from '@ngbracket/ngx-layout';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {CoreModule} from '@core/core.module';
import {CustomerCategoryDialogModule} from '@management/customer/customer-category/customer-category-dialog.module';
import {CustomerCategoryDropdownComponent} from '@customer/components/dropdowns/customer-category-dropdown/customer-category-dropdown.component';


@NgModule({
    declarations: [CustomerCategoryDropdownComponent],
    exports: [
        CustomerCategoryDropdownComponent
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        TranslateModule,
        MatCardModule,
        FlexModule,
        MatButtonModule,
        CustomerCategoryDialogModule,
        CoreModule
    ]
})
export class CustomerCategoryDropdownModule {
}
