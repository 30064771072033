import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContainerWithSidenavComponent} from '@core/layouts/components/container-with-sidenav/container-with-sidenav.component';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {FlexModule} from '@ngbracket/ngx-layout';


@NgModule({
    declarations: [ContainerWithSidenavComponent],
    imports: [
        CommonModule,
        MatListModule,
        FlexModule
    ],
    exports: [ContainerWithSidenavComponent]
})
export class ContainerWithSidenavModule {
}
