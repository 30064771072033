import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PaymentConditionModel} from '@customer/models';
import {CustomerMiscService} from '@customer/customer-misc.service';
import {PaymentTypeModel} from '@core/models/user/payment-type.model';
import {tap} from 'rxjs/operators';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {EntityDropdown} from '@core/components/entity-dropdown/entity-dropdown.component';
import {PaymentConditionDialogComponent} from '@management/customer/payment-condition/payment-condition-dialog.component';

@Component({
    selector: 'omt-payment-condition-dropdown',
    templateUrl: './payment-condition-dropdown.component.html',
    styleUrls: ['./payment-condition-dropdown.component.scss']
})
export class PaymentConditionDropdownComponent extends EntityDropdown {
    @Input() paymentCondition: PaymentConditionModel;
    @Output() paymentConditionChange = new EventEmitter<PaymentConditionModel>();

    paymentConditions$ = this.miscService.readPaymentCondition();

    constructor(private readonly miscService: CustomerMiscService,
                private readonly dialog: MatDialog) {
        super();
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(PaymentConditionDialogComponent, {
            width: '1000px',
            disableClose: true,
            data: new PaymentTypeModel(),
            position: {top: '3%'},
            maxHeight: '90vh'
        });

        this.subs.sink = dialogRef.componentInstance.saveEmitter.pipe(
            tap((newCondition) => {
                this.paymentCondition = newCondition;
                this.paymentConditionChange.emit(newCondition);
            })
        ).subscribe(() => this.paymentConditions$ = this.miscService.readPaymentCondition());
    }

}
