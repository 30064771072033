<mat-card class="vh-70 p-0" fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between">
  <omt-container-with-sidenav>
    <mat-list sidenav fxLayout="column" fxLayout.xs="row" fxLayoutGap.xs="15px" [ngClass.xs]="{'of-scroll': true}">
      <mat-list-item (click)="selectedTab = 'user'" class="cursor-pointer">
        <button class="w-100-p" mat-stroked-button [ngClass]="{active: selectedTab === 'user'}">Benutzer</button>
      </mat-list-item>
    </mat-list>

    <div main>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h3>{{ 'CUSTOMER.CUSTOMER_PORTAL_INFOTEXT' | translate }}
          <a [href]="customerPortalUrl" target="_blank">{{ 'CUSTOMER.LINK_TO_PORTAL' | translate }}</a>
        </h3>

        <button (click)="openDialog(null)" mat-button class="rounded" color="primary">{{ 'CUSTOMER.CREATE_USER' | translate }}</button>
      </div>


      @if (!isLoading) {
        <div class="table-responsive mt-5">
          <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="activated">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CUSTOMER.ACTIVATED' | translate }}</th>
              <td mat-cell *matCellDef="let data">
                @if (data.activated) {
                  <i class="fas fa-check-circle text-green"></i>
                } @else {
                  <i class="fas fa-times-circle text-red"></i>
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>E-Mail</th>
              <td mat-cell *matCellDef="let data">
                {{ data.email }}
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let data">
                <div fxLayout="row" fxLayoutAlign="end center">
                  <i class="fa fa-pen text-muted" [matTooltip]="'GLOBAL.EDIT' | translate" (click)="openDialog(data)"></i>
                  <i class="fa fa-trash delete-icon text-muted ml-1" [matTooltip]="'GLOBAL.DELETE' | translate" (click)="delete(data)"></i>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-pointer"></tr>
          </table>
          @if (dataSource.data.length === 0) {
            <span>{{ 'CUSTOMER.NO_USER_AVAILABLE' | translate }}</span>
          }
          <mat-paginator [pageSizeOptions]="[15, 25, 50, 100, 250]"></mat-paginator>
        </div>
      } @else {
        <omt-spinner></omt-spinner>
      }
    </div>
  </omt-container-with-sidenav>
</mat-card>
