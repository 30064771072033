import {HttpEvent, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CustomerDocumentModel} from '@customer/models';
import {ApiService} from '@core/services/api.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerDocumentService {
    constructor(private readonly apiService: ApiService) {
    }

    createCustomerDocument(customerId: number, file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        const httpOptions = {
            headers: new HttpHeaders({
                'skip-content-type': 'skip'
            }),
            params: new HttpParams().set('customerId', customerId.toString())
        };

        // const req = new HttpRequest('POST', 'api/Customer/CreateCustomerDocument', formData, httpOptions);
        return this.apiService.post('api/Customer/CreateCustomerDocument', formData, httpOptions);
    }

    updateCustomerDocument(document: CustomerDocumentModel): Observable<CustomerDocumentModel> {
        return this.apiService.put<CustomerDocumentModel>('api/Customer/UpdateCustomerDocument', document, {});
    }

    readCustomerDocuments(customerId: number): Observable<CustomerDocumentModel[]> {
        const urlParams = new HttpParams().set('customerId', customerId.toString());

        return this.apiService.get<CustomerDocumentModel[]>('api/Customer/ReadCustomerDocuments', urlParams);
    }

    deleteCustomerDocument(id: number): Observable<any> {
        const urlParams = new HttpParams().set('id', id.toString());
        return this.apiService.delete('api/Customer/DeleteCustomerDocument', urlParams);
    }
}
