import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CustomerDocumentModel} from '@customer/models';
import {CustomerDocumentService} from '@customer/customer-document.service';

@Component({
    selector: 'omt-customer-attachment-dialog',
    templateUrl: './customer-attachment-dialog.component.html',
    styleUrls: ['./customer-attachment-dialog.component.scss']
})
export class CustomerAttachmentDialogComponent {
    // -----------------------------------------------------------------------------------------------------
    // @ Declarations
    // -----------------------------------------------------------------------------------------------------

    @Output() updatedDocument = new EventEmitter<CustomerDocumentModel>();
    document: CustomerDocumentModel;

    constructor(public dialogRef: MatDialogRef<CustomerAttachmentDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data,
                public customerDocService: CustomerDocumentService) {
        this.document = data;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Actions
    // -----------------------------------------------------------------------------------------------------

    save(): void {
        this.customerDocService.updateCustomerDocument(this.document).subscribe((data) => {
            this.updatedDocument.emit(data);
            this.closeModal();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Dialog
    // -----------------------------------------------------------------------------------------------------

    closeModal(): void {
        this.dialogRef.close();
    }
}
