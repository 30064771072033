<mat-card class="vh-70" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="15px">
  <div #drpzone="ngxDropzone" class="dropzone" [dropzone]="dropzoneConfig" (complete)="loadDocuments()"></div>

  @if (!dataSource || !dataSource.data || !(dataSource.data.length > 0)) {
    <div>
      {{ 'CUSTOMER.NO_ATTACHMENTS_AVAILABLE' | translate }}
    </div>
  }

  @if (dataSource && dataSource.data && dataSource.data.length > 0) {
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let data">
            <a [href]="environment.apiUrl + data.fileUrl" target="_blank" rel="noopener" fxLayout="row" fxLayoutAlign="start center">
              <i class="fas fa-file text-muted"></i>
              <span>{{ data.name }}</span>
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CUSTOMER.SIZE' | translate }}</th>
          <td mat-cell *matCellDef="let data">
            {{ data.fileSize }}
          </td>
        </ng-container>
        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CUSTOMER.COMMENT' | translate }}</th>
          <td mat-cell *matCellDef="let data">
            {{ data.description }}
          </td>
        </ng-container>
        <ng-container matColumnDef="updatedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CUSTOMER.DATE_OF_MODIFICATION' | translate }}</th>
          <td mat-cell *matCellDef="let data">
            {{ data.updatedAt | germanDateFormat | empty }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let data">
            <i class="fa fa-pen text-muted" (click)="openDialog(data)" [matTooltip]="'CUSTOMER.RECORD_EDIT' | translate"></i>
            <i class="fa fa-trash-alt text-muted ml-1" (click)="deleteDocument(data.id)" [matTooltip]="'CUSTOMER.DELETE_DATA_RECORD' | translate"></i>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-pointer"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
  }
</mat-card>
