import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PaymentTypeModel} from '@core/models/user/payment-type.model';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CustomerMiscService} from '@customer/customer-misc.service';
import {tap} from 'rxjs/operators';
import {EntityDropdown} from '@core/components/entity-dropdown/entity-dropdown.component';
import {PaymentTypeDialogComponent} from '@management/customer/payment-type/payment-type-dialog.component';

@Component({
    selector: 'omt-payment-type-dropdown',
    templateUrl: './payment-type-dropdown.component.html',
    styleUrls: ['./payment-type-dropdown.component.scss']
})
export class PaymentTypeDropdownComponent extends EntityDropdown {
    @Input() paymentType: PaymentTypeModel;
    @Output() paymentTypeChange = new EventEmitter<PaymentTypeModel>();

    paymentTypes$ = this.miscService.readPaymentType();

    constructor(private readonly miscService: CustomerMiscService,
                private readonly dialog: MatDialog) {
        super();
    }

    openPaymentTypeDialog(paymentTypeModel: PaymentTypeModel = new PaymentTypeModel()): void {
        const dialogRef = this.dialog.open(PaymentTypeDialogComponent, {
            width: '1000px',
            disableClose: true,
            data: paymentTypeModel,
            position: {top: '3%'},
            maxHeight: '90vh'
        });

        this.subs.sink = dialogRef.componentInstance.saveEmitter.pipe(
            tap((newType) => {
                this.paymentType = newType;
                this.paymentTypeChange.emit(newType);
            })
        ).subscribe(() => this.paymentTypes$ = this.miscService.readPaymentType());
    }
}
