import {Component, Input} from '@angular/core';

@Component({
    selector: 'omt-hourly-rate-host',
    templateUrl: './hourly-rate-host.component.html',
    styleUrls: ['./hourly-rate-host.component.scss']
})
export class HourlyRateHostComponent {
    @Input()
    set customerId(val: number) {
        this._customerId = val;
    }

    get customerId(): number {
        return this._customerId;
    }

    private _customerId: number;

    propositionEnum = HourlyRateHostComponentsEnum;
    activeComponent: HourlyRateHostComponentsEnum = this.propositionEnum.HOURLY_RATE;

    constructor() {
    }

}

export enum HourlyRateHostComponentsEnum {
    HOURLY_RATE,
    CUSTOMER_REFS
}
