import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';

import {merge, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NavigationService} from '@core/services/global/navigation.service';
import {FuseNavigation} from '../../types';
import {FuseNavigationService} from './navigation.service';
import {locale as navigationEnglish} from '../../../i18n/navigation/en';
import {locale as navigationDeutsch} from '../../../i18n/navigation/de';
import {locale as globalEnglish} from '../../../i18n/global/en';
import {locale as globalDeutsch} from '../../../i18n/global/de';
import {locale as ticketsEnglish} from '../../../i18n/tickets/en';
import {locale as ticketsDeutsch} from '../../../i18n/tickets/de';
import {locale as timetrackingEnglish} from '../../../i18n/timetracking/en';
import {locale as timetrackingDeutsch} from '../../../i18n/timetracking/de';
import {locale as boardsEnglish} from '../../../i18n/boards/en';
import {locale as boardsDeutsch} from '../../../i18n/boards/de';
import {locale as humanresourceDeutsch} from '../../../i18n/humanresource/de';
import {locale as humanresourceEnglish} from '../../../i18n/humanresource/en';
import {locale as inventoryDeutsch} from '../../../i18n/inventory/de';
import {locale as inventoryEnglish} from '../../../i18n/inventory/en';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default
})
export class FuseNavigationComponent implements OnInit, OnDestroy {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: FuseNavigation[];

    promise: Promise<FuseNavigation[]>;

    // Private
    private _unsubscribeAll: Subject<void>;

    isLoading = true;

    /**
     *
     *
     *
     *
     * @param _changeDetectorRef
     * @param _fuseNavigationService
     * @param navigationService
     * @param _fuseTranslationLoaderService
     */
    constructor(private _changeDetectorRef: ChangeDetectorRef, private _fuseNavigationService: FuseNavigationService, public navigationService: NavigationService,
                private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationDeutsch, globalEnglish, globalDeutsch,
            ticketsEnglish, ticketsDeutsch, timetrackingEnglish, timetrackingDeutsch, boardsEnglish, boardsDeutsch, humanresourceDeutsch, humanresourceEnglish,
            inventoryDeutsch, inventoryEnglish);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void> {
        this.navigationService.nodes.subscribe((nodes) => this.navigation = nodes);

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            // Load the navigation
            // this.navigation = this._fuseNavigationService.getCurrentNavigation();

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });

        // Subscribe to navigation item
        merge(this._fuseNavigationService.onNavigationItemAdded, this._fuseNavigationService.onNavigationItemUpdated, this._fuseNavigationService.onNavigationItemRemoved)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
