@if (isLoading) {
  <omt-spinner></omt-spinner>
}

<div class="page-layout simple">
  <div *headerActions ngProjectAs="actions" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="w-100-p" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <omt-search-input [matTooltip]="'GLOBAL.SEARCH' | translate" (searchChanged)="filter($event)"></omt-search-input>
      <button (click)="openDialog()" [matTooltip]="'CUSTOMER.NEW_CUSTOMER' | translate" color="primary" mat-mini-fab type="button">
        <i class="fas fa-plus text-white"></i>
      </button>
    </div>
  </div>

  <div class="content-spacer pt-0">
    <div [hidden]="isLoading" fxLayout="column" class="w-100-p">
      <div class="table-responsive box-shadow w-100-p">
        <table class="table-enlarged" mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="name">
            <th width="20%" mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let customer">
              <div fxLayout="row" fxLayoutAlign="start center">
                <img [src]="customer.avatarUrl" class="width-50 avatar"/>
                <span>{{ customer.displayName }}</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="number">
            <th width="8%" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CUSTOMER.CUSTOMER_NO' | translate }}</th>
            <td mat-cell *matCellDef="let customer">
              <div fxLayout="row" fxLayoutAlign="start center">
                <omt-number-range-key [key]="customer.customerKey"></omt-number-range-key>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="street">
            <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'CUSTOMER.STREET' | translate }}
            </th>
            <td mat-cell *matCellDef="let customer">{{ customer.street }} {{ customer.houseNumber }}</td>
          </ng-container>
          <ng-container matColumnDef="city">
            <th width="8%" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'CUSTOMER.CITY' | translate }}
            </th>
            <td mat-cell *matCellDef="let customer">
              {{ customer.city }}
            </td>
          </ng-container>
          <ng-container matColumnDef="contact">
            <th width="15%" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'CUSTOMER.CONTACT' | translate }}
            </th>
            <td mat-cell *matCellDef="let customer">
              {{ customer.email }} @if (customer.email) {
              <br/>
            }
              {{ customer.phone }}
            </td>
          </ng-container>
          <ng-container matColumnDef="category">
            <th width="5%" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'CUSTOMER.CATEGORY' | translate }}
            </th>
            <td mat-cell *matCellDef="let customer">
              @if (customer.category) {
                <span>{{ customer.category.name }}</span>
              }
              @if (!customer.category) {
                <span>-</span>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="board">
            <th width="5%" mat-header-cell *matHeaderCellDef mat-sort-header>Board</th>
            <td mat-cell *matCellDef="let customer">
              <button mat-button [routerLink]="['/boards/assigned-ticket-board/customer', customer.id]">Tickets ({{ customer.openTickets }})</button>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th width="4%" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CUSTOMER.STATUS' | translate }}</th>
            <td mat-cell *matCellDef="let customer">
              @if (!customer.disabled) {
                <div fxLayout="row" fxLayoutAlign="start center">
                  <i class="fas fa-check text-green"></i>
                  <span>aktiv</span>
                </div>
              } @else {
                <div fxLayout="row" fxLayoutAlign="start center">
                  <i class="fas fa-times text-red"></i>
                  <span>inaktiv</span>
                </div>
              }
            </td>
          </ng-container>


          <ng-container matColumnDef="actions">
            <th width="2%" mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let customer" (click)="$event.stopPropagation()">
              <omt-favorite-button [entityId]="customer.id" type="customer"></omt-favorite-button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr *matNoDataRow>
            <td [colSpan]="displayedColumns.length">
              @if (!isLoading) {
                <omt-missing-entries-info
                  icon="fas fa-user"
                  [infoText]="searchFilter ? ('GLOBAL.NO_SEARCH_RESULTS' | translate) : ('CUSTOMER.NO_CUSTOMERS' | translate)"
                  [additionalInfo]="searchFilter ? '' : ('CUSTOMER.CUSTOMERS_INFOTEXT' | translate)"></omt-missing-entries-info>
              }
            </td>
          </tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="['/customer', row.id]" class="cursor-pointer"></tr>
        </table>
        <mat-paginator (page)="setPageSize($event)" [pageSizeOptions]="[15, 25, 50, 100, 250]" [pageSize]="getPageSize()"></mat-paginator>
      </div>
    </div>
  </div>
</div>
