<mat-card class="p-3 vh-70" fxLayout="column">
  <omt-note [canCreate]="true"
            [notes]="customerNotes"
            (deleteClicked)="onDelete($event.id)"
            [hasTitle]="true"
            [noteMaxLength]="noteMaxLength"
            [titleMaxLength]="titleMaxLength"
            [hasRichEditor]="true"
            [hasType]="true"
            [types]="noteTypes"
            [typeTemplate]="typeTemplate"
            (createdNote)="onCreate($event)"></omt-note>

  <ng-template #typeTemplate let-note>
    <span class="badge badge-outline badge-outline-info">
      @switch (note.type) {
        @case (customerNoteType.VisitReport) {
          Besuchsbericht
        }
        @case (customerNoteType.Note) {
          Notiz
        }
        @case (customerNoteType.Task) {
          Aufgabe
        }
        @case (customerNoteType.Resubmission) {
          Wiedervorlage
        }
        @case (customerNoteType.PhoneEntry) {
          Telefoneintrag
        }
        @default {

        }
      }
    </span>
  </ng-template>
</mat-card>
