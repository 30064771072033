import {Component, Input} from '@angular/core';
import {environment} from '@env/environment';
import {DialogService} from '@core/services/global/dialog.service';
import {ConfirmDialogComponentData} from '@shared/components/delete-dialog/confirm-dialog-component-data';
import {CustomerModel, CustomerReferencePersonModel} from '@customer/models';
import {CustomerService} from '@customer/customer.service';

@Component({
    selector: 'omt-customer-reference-person',
    templateUrl: './customer-reference-person.component.html',
    styleUrls: ['./customer-reference-person.component.scss']
})
export class CustomerReferencePersonComponent {
    // -----------------------------------------------------------------------------------------------------
    // @ Declarations
    // -----------------------------------------------------------------------------------------------------

    @Input() customer: CustomerModel;
    @Input() editMode: boolean;

    constructor(
        private readonly customerService: CustomerService,
        private readonly dialogService: DialogService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Actions
    // -----------------------------------------------------------------------------------------------------

    refPersonsAvailable(): boolean {
        return this.customer.referencePersons != null && this.customer.referencePersons.length > 0;
    }

    uploadImage(image: File, id: number): void {
        this.customerService.saveReferencePersonImage(image, id).subscribe((data: CustomerReferencePersonModel) => {
            const foundRef = this.customer.referencePersons.find((x) => x.id === id);
            foundRef.avatarUrl = environment.apiUrl + data.avatarUrl;
        });
    }

    delete(id: number): void {
        this.customerService.deleteReferencePerson(id).subscribe(() => {
            this.customer.referencePersons = this.customer.referencePersons.filter((x) => x.id !== id);
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Dialog
    // -----------------------------------------------------------------------------------------------------

    openDialog(ref: CustomerReferencePersonModel = null): void {
        const dialogRef = this.dialogService.openCustomerReferencePersonDialog({customerId: this.customer.id, refPerson: ref});

        dialogRef.componentInstance.newPerson.subscribe((event) => {
            this.customer.referencePersons.push(event);
        });

        dialogRef.componentInstance.updatedPerson.subscribe((event: CustomerReferencePersonModel) => {
            let foundRef = this.customer.referencePersons.find((x) => x.id === event.id);
            foundRef = {...foundRef, ...event};
        });
    }

    openDeleteDialog(id: number): void {
        const dialogData = new ConfirmDialogComponentData('Ansprechpartner löschen', 'Soll dieser Ansprechpartner wirklich gelöscht werden?');
        const dialogRef = this.dialogService.openConfirmDialog(dialogData);

        dialogRef.componentInstance.deleteClicked.subscribe((emit) => {
            if (emit) {
                this.delete(id);
            }
        });
    }
}
