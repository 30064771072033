<div class="nav" [ngClass]="{horizontal: layout === 'horizontal', vertical: layout === 'vertical'}">
  <!-- Vertical Navigation Layout -->
  @if (navigationService.nodes | async) {
    @if (layout === 'vertical') {
      @for (item of navigation; track item.id) {
        @if (item.type === 'group') {
          <fuse-nav-vertical-group [item]="item"></fuse-nav-vertical-group>
        }
        @if (item.type === 'collapsable') {
          <fuse-nav-vertical-collapsable [item]="item"></fuse-nav-vertical-collapsable>
        }
        @if (item.type === 'item') {
          <fuse-nav-vertical-item [item]="item"></fuse-nav-vertical-item>
        }
      }
    }
    <!-- / Vertical Navigation Layout -->
  }
</div>
