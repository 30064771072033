import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {SalutationModel} from '@core/models/user/salutation.model';
import {DialogService} from '@core/services/global/dialog.service';
import {ConfirmDialogComponentData} from '@shared/components/delete-dialog/confirm-dialog-component-data';
import {TranslateService} from '@ngx-translate/core';
import {CustomerCategoryModel, CustomerModel} from '@customer/models';
import {CustomerService} from '@customer/customer.service';
import {CustomerMiscService} from '@customer/customer-misc.service';
import {filter, switchMap} from 'rxjs/operators';
import {SubSink} from 'subsink';

@Component({
    selector: 'omt-customer-contact',
    templateUrl: './customer-contact.component.html',
    styleUrls: ['./customer-contact.component.scss']
})
export class CustomerContactComponent implements OnInit, OnChanges, OnDestroy {
    @Input() customer: CustomerModel;
    @Input() editMode = false;
    temp: CustomerModel;
    categories: CustomerCategoryModel[] = [];
    salutations: SalutationModel[] = [];
    invoiceSameAddress = false;

    invoiceAddressString: string;

    private subs = new SubSink();

    constructor(
        private readonly customerService: CustomerService,
        private readonly router: Router,
        private readonly dialogService: DialogService,
        private readonly miscService: CustomerMiscService,
        private readonly translateService: TranslateService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        this.temp = {...this.customer};
        this.loadSalutations();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.customer) {
            if (this.customer.id) {
                this.setInvoiceAddress();
            }
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Load
    // -----------------------------------------------------------------------------------------------------

    loadSalutations(): void {
        this.subs.sink = this.miscService.readSalutation().subscribe((data) => {
            this.salutations = data;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Actions
    // -----------------------------------------------------------------------------------------------------

    uploadImage(image: File): void {
        this.subs.sink = this.customerService.saveImage(image, this.customer.id).subscribe((data: CustomerModel) => {
            this.customer = data;
        });
    }

    onDelete(): void {
        const dialogData = new ConfirmDialogComponentData(this.translateService.instant('CUSTOMER.CONFIRM'), this.translateService.instant('CUSTOMER.CONFIRMATION_OF_DELETION'));
        const dialogRef = this.dialogService.openConfirmDialog(dialogData);

        this.subs.sink = dialogRef.componentInstance.deleteClicked
            .pipe(
                filter((clicked) => !!clicked),
                switchMap(() => this.customerService.delete(this.customer.id))
            )
            .subscribe(() => this.router.navigate(['/customer']));
    }

    setInvoiceToContactAddress(): void {
        if (this.invoiceSameAddress) {
            this.customer.invoiceCountry = this.customer.country;
            this.customer.invoiceCity = this.customer.city;
            this.customer.invoicePostcode = this.customer.postcode;
            this.customer.invoiceHouseNumber = this.customer.houseNumber;
            this.customer.invoiceStreet = this.customer.street;
            this.customer.invoiceLastName = this.customer.lastName;
            this.customer.invoiceFirstName = this.customer.firstName;
        } else {
            this.customer.invoiceCountry = this.temp.invoiceCountry;
            this.customer.invoiceCity = this.temp.invoiceCity;
            this.customer.invoicePostcode = this.temp.invoicePostcode;
            this.customer.invoiceHouseNumber = this.temp.invoiceHouseNumber;
            this.customer.invoiceStreet = this.temp.invoiceStreet;
            this.customer.invoiceFirstName = this.temp.invoiceFirstName;
            this.customer.invoiceLastName = this.temp.invoiceLastName;
        }
    }

    updateInvoice(): void {
        if (!this.invoiceSameAddress) {
            return;
        }

        this.setInvoiceToContactAddress();
    }

    private setInvoiceAddress(): void {
        const street = `${this.customer.invoiceStreet ?? ''} ${this.customer.invoiceHouseNumber ?? ''}
                            ${this.customer.invoiceStreet || this.customer.invoiceHouseNumber ? ',' : ''}`;
        const postCodeAndCity = `${this.customer.invoicePostcode ?? ''} ${this.customer.invoiceCity ?? ''}${this.customer.invoicePostcode || this.customer.invoiceCity ? ', ' : ''}`;
        this.invoiceAddressString = `${street} ${postCodeAndCity} ${this.customer.invoiceCountry ?? ''}`;
    }
}
