import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaymentConditionDropdownComponent} from '@shared/components/dropdowns/payment-condition-dropdown/payment-condition-dropdown.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {FlexModule} from '@ngbracket/ngx-layout';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {CoreModule} from '@core/core.module';


@NgModule({
    declarations: [PaymentConditionDropdownComponent],
    exports: [
        PaymentConditionDropdownComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        NgSelectModule,
        FormsModule,
        MatCardModule,
        MatButtonModule,
        FlexModule,
        MatTooltipModule,
        TranslateModule
    ]
})
export class PaymentConditionDropdownModule {
}
