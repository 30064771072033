export const locale = {
    lang: 'en',
    data: {
        NAV: {
            // ----------------------------------------------------------------------------------------------------- //
            // NAVIGATION
            // ----------------------------------------------------------------------------------------------------- //

            PRESENCE: 'Presence',
            BOOKING_CORRECTION: 'Booking correction',
            CALENDAR: 'Calendar',
            DAY_JOURNAL: 'Daily journal',
            MONTH_JOURNAL: 'Month journal',
            CUSTOMER: 'Customer',
            HUMAN_RESOURCE: 'Human Resource',
            ABSENCE_REASON: 'Absence Reasons',
            WAREHOUSE: 'Warehouse',
            REPORTING: 'Reporting',
            MANAGEMENT: 'Management',
            SETTING: 'Settings'
        }
    }
};
