<mat-card class="vh-70" fxLayout="column">
  @if (!refPersonsAvailable()) {
    <div class="w-100-p h-100-p" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
      <span>{{ 'CUSTOMER.NO_CONTACT_PERSON_AVAILABLE' | translate }}</span>
      <button (click)="openDialog()" mat-button class="rounded" color="primary">{{ 'CUSTOMER.CREATE_CONTACT_PERSON' | translate }}</button>
    </div>
  }
  @if (refPersonsAvailable()) {
    <div fxLayout="row" fxLayoutAlign="center">
      <button (click)="openDialog()" mat-button class="rounded" color="primary">{{ 'CUSTOMER.CREATE_CONTACT_PERSON' | translate }}</button>
    </div>
    @for (ref of customer.referencePersons; track ref) {
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start start" class="mb-1">
        <omt-image-cropper class="w-230 p-10 mr-5" [image]="ref.avatarUrl" [width]="230" (imageSelected)="uploadImage($event, ref.id)"></omt-image-cropper>
        <div fxLayout="column" class="mt-1">
          <!--                Heading and action buttons -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <h1 class="mt-0 mb-0">{{ ref.firstName }} {{ ref.lastName }}</h1>
            <span class="text-muted ml-5">
              <i (click)="openDialog(ref)" class="fa fa-pen cursor-pointer mr-1" [matTooltip]="'GLOBAL.EDIT' | translate"></i>
              <i (click)="openDeleteDialog(ref.id)" class="fa fa-trash cursor-pointer" [matTooltip]="'GLOBAL.DELETE' | translate"></i>
            </span>
          </div>
          <div fxLayout="column" id="contact" fxLayoutGap="15px">
            <div fxLayout="row" class="mb-3">
              @if (ref.department) {
                <h3 class="text-muted sub-heading">{{ 'CUSTOMER.DEPARTMENT' | translate }} {{ ref.department }}</h3>
              }
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
              <i class="fas fa-envelope"></i>
              <a href="mailto:{{ ref.email }}">{{ ref.email | empty }}</a>
            </div>
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start center" fxLayoutAlign.lt-lg="start start" fxLayoutGap="25px" fxLayoutGap.lt-lg="15px">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                <i class="fas fa-phone"></i>
                <a href="tel:{{ ref.phone }}">{{ ref.phone | empty }}</a>
                <span class="text-muted">({{ 'CUSTOMER.FOR_BUSINESS' | translate }})</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                <a href="tel:{{ ref.mobile }}">{{ ref.mobile | empty }}</a>
                <span class="text-muted">({{ 'CUSTOMER.MOBILE' | translate }})</span>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
              <i class="fas fa-fax"></i>
              <span>{{ ref.fax | empty }}</span>
            </div>
          </div>
        </div>
      </div>
    }
  }
</mat-card>
