import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '@core/models/user/user.model';
import {CustomerNoteModel, CustomerNoteType} from '@customer/models';
import {CustomerService} from '@customer/customer.service';
import {NoteModel} from '@core/components/note/note.model';
import {NoteTypeOption} from '@core/components/note/note.component';

@Component({
    selector: 'omt-customer-note',
    templateUrl: './customer-note.component.html',
    styleUrls: ['./customer-note.component.scss']
})
export class CustomerNoteComponent implements OnInit {
    customerNotes: CustomerNoteModel[] = [];
    readonly currentUser: UserModel;
    readonly customerId: number;
    readonly titleMaxLength = 200;
    readonly noteMaxLength = 10_000;

    readonly customerNoteType = CustomerNoteType;
    readonly noteTypes: NoteTypeOption<CustomerNoteType>[] = [
        {name: 'Besuchsbericht', value: CustomerNoteType.VisitReport},
        {name: 'Notiz', value: CustomerNoteType.Note},
        {name: 'Aufgabe', value: CustomerNoteType.Task},
        {name: 'Wiedervorlage', value: CustomerNoteType.Resubmission},
        {name: 'Telefoneintrag', value: CustomerNoteType.PhoneEntry}
    ];

    constructor(
        private readonly customerService: CustomerService,
        private readonly route: ActivatedRoute) {
        this.customerId = this.route.snapshot.params.id;
    }

    ngOnInit(): void {
        this.loadNotes();
    }

    loadNotes(): void {
        this.customerService.getCustomerNotices(this.customerId).subscribe((data: CustomerNoteModel[]) => this.customerNotes = data);
    }

    onCreate(note: NoteModel<CustomerNoteType>): void {
        const newNote = new CustomerNoteModel(note);
        newNote.customerId = this.customerId;
        this.customerService.createNotice(newNote).subscribe((data) => this.customerNotes = [data, ...this.customerNotes]);
    }

    onDelete(noticeId: number): void {
        this.customerService.deleteNotice(noticeId).subscribe(() => this.customerNotes = [...this.customerNotes.filter((x) => x.id !== noticeId)]);
    }
}
