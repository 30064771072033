import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CustomerDetailComponent} from './components/customer-detail/customer-detail.component';
import {CustomerComponent} from './components/customer.component';

const routes: Routes = [
    {
        path: '',
        component: CustomerComponent
    },
    {
        path: ':id',
        component: CustomerDetailComponent,
        data: {title: 'Kunde'}
    }
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CustomerRoutingModule {
}
