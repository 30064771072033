<omt-dialog-host title="Stundensatz anpassen" (closeClicked)="closeDialog()">
  <div dialog-content>
    <!-- #region Name-->
    <div class="p-2" fxLayout="column" [fxLayoutGap]="layout.layoutGap.desktop">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span fxFlex="25"
        >Typ
          <span class="text-red">*</span>
        </span>
        <div fxFlex="40" fxLayout="column">
          <omt-worklog-type-dropdown [(value)]="workLogRate.workLogType" (valueChange)="workLogRate.workLogTypeId = $event?.id"></omt-worklog-type-dropdown>
        </div>
      </div>
      <!--endregion Name-->

      <!-- #region Account -->
      <div fxLayout="row" fxLayoutAlign="start center">
        <span fxFlex="25">{{ 'MANAGEMENT.HOURLY_RATE' | translate }}</span>
        <div fxFlex="40" fxLayout="column">
          <input class="redesign" [(ngModel)]="workLogRate.hourlyRate" type="number" required/>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center">
        <span fxFlex="25">{{ 'MANAGEMENT.MAINTENANCE_VOLUME' | translate }}</span>
        <div fxFlex="40" fxLayout="column">
          <input class="redesign" [(ngModel)]="workLogRate.maintenanceVolume" type="number"/>
        </div>
      </div>
    </div>
    <!--endregion Account-->
  </div>
  <div dialog-footer class="modal-actions">
    <button mat-fab color="primary" type="button" (click)="save()">
      <i class="fas fa-check fa-2x"></i>
    </button>
  </div>
</omt-dialog-host>
