import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {CustomerCreateUserDialogComponent} from '../../customer-create-user-dialog/customer-create-user-dialog.component';
import {CustomerUserHttpService} from '@customer/components/customer-detail/customer-accounts/customer-user-http.service';
import {SubSink} from 'subsink';
import {CustomerUserModel} from '@customer/components/customer-detail/customer-accounts/customer-user.model';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {DialogService} from '@core/services/global/dialog.service';
import {UserSettingsService} from '@core/services/user-settings.service';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {environment} from '@env/environment';

@Component({
    selector: 'omt-customer-accounts',
    templateUrl: './customer-accounts.component.html',
    styleUrls: ['./customer-accounts.component.scss']
})
export class CustomerAccountsComponent implements OnInit, OnDestroy {
    @ViewChild(MatSort, {static: false}) set sort(sort: MatSort) {
        this._sort = sort;

        if (this._sort) {
            this.dataSource.sort = this._sort;
        }
    }

    @ViewChild(MatPaginator, {static: false}) set paginator(paginator: MatPaginator) {
        this._paginator = paginator;

        if (this._paginator) {
            this.dataSource.paginator = this._paginator;
        }
    }

    @Input() customerId: number;
    dataSource = new MatTableDataSource();
    displayedColumns = ['activated', 'email', 'actions'];
    selectedTab = 'user';
    pageSize: number;
    isLoading = true;
    customerPortalUrl = environment.portalUrl;

    // private declarations
    private _sort: MatSort;
    private _paginator: MatPaginator;
    private subs = new SubSink();
    private _accounts: CustomerUserModel[] = [];

    set accounts(acts: CustomerUserModel[]) {
        this._accounts = acts;
        this.tableConfiguration();
    }

    constructor(private readonly dialog: MatDialog,
                private readonly userSettingsService: UserSettingsService,
                private readonly customerUserHttpService: CustomerUserHttpService,
                private readonly dialogService: DialogService) {
    }

    ngOnInit(): void {
        this.subs.sink = this.userSettingsService.userSettings.subscribe((x) => this.pageSize = x.paginationDefaultPageSize);

        this.load();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Actions
    // -----------------------------------------------------------------------------------------------------

    load(): void {
        this.subs.sink = this.customerUserHttpService.getAll(this.customerId)
            .pipe(tap(() => this.isLoading = false))
            .subscribe((x) => this.accounts = [...x]);
    }

    delete(row: CustomerUserModel): void {
        const dialogRef = this.dialogService.openConfirmDialog();

        this.subs.sink = dialogRef.componentInstance.deleteClicked.subscribe((clicked) => {
            if (clicked) {
                this.subs.sink = this.customerUserHttpService.delete(row.id)
                    .subscribe((x) => this.accounts = [...this._accounts.filter((item) => item.id !== x.id)]);
            }
        });
    }

    tableConfiguration(items?: CustomerUserModel[]): void {
        items ??= this._accounts;
        this.dataSource = new MatTableDataSource();

        this.dataSource.sort = this._sort;
        this.dataSource.paginator = this._paginator;

        setTimeout(() => this.dataSource.data = [...items], 1);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Dialog
    // -----------------------------------------------------------------------------------------------------

    openDialog(user: CustomerUserModel): void {
        const dialogRef = this.dialog.open(CustomerCreateUserDialogComponent, {
            width: '700px',
            data: user,
            disableClose: true,
            panelClass: ['mat-dialog-overflow', 'dialog-0-p']
        });

        this.subs.sink = dialogRef.componentInstance.userCreated
            .pipe(
                map((x) => {
                    x.customerId = this.customerId;
                    return x;
                }),
                switchMap((x) => this.customerUserHttpService.create(x).pipe(
                    catchError((err) => {
                        console.log(err);
                        return of(null);
                    })
                ))
            )
            .subscribe((x) => {
                if (x) {
                    user = {...x};
                    this.accounts = [...this._accounts, user];
                    dialogRef.componentInstance.closeDialog();
                }
            });

        this.subs.sink = dialogRef.componentInstance.userEdited
            .pipe(
                switchMap((x) => this.customerUserHttpService.update(x).pipe(
                    catchError((err) => {
                        console.log(err);
                        return of(null);
                    })
                )))
            .subscribe((x) => {
                if (x) {
                    user = {...x};
                    dialogRef.componentInstance.closeDialog();
                }
            });
    }
}
