@if (!item.hidden) {
  <!-- item.url -->
  @if (item.url && !item.externalUrl && !item.function) {
    <a
      class="nav-link"
      [ngClass]="item.classes"
      [routerLink]="[item.url]"
      [routerLinkActive]="['active', 'dark-active']"
      [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  }
  <!-- item.externalUrl -->
  @if (item.url && item.externalUrl && !item.function) {
    <a class="nav-link" [ngClass]="item.classes" [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  }
  <!-- item.function -->
  @if (!item.url && item.function) {
    <span class="nav-link" [ngClass]="item.classes" (click)="item.function()">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>
  }
  <!-- item.url && item.function -->
  @if (item.url && !item.externalUrl && item.function) {
    <a
      class="nav-link"
      [ngClass]="item.classes"
      (click)="item.function()"
      [routerLink]="[item.url]"
      [routerLinkActive]="['active', 'background-red']"
      [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  }
  <!-- item.externalUrl && item.function -->
  @if (item.url && item.externalUrl && item.function) {
    <a
      class="nav-link"
      [ngClass]="item.classes"
      (click)="item.function()"
      [href]="item.url"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  }
  <ng-template #itemContent>
    @if (item.icon && !item.svg) {
      <i [ngClass]="item.icon" class="nav-link-icon" [style.color]="'#' + item.colorCode"></i>
    }
    @if (item.icon && item.svg) {
      <img src="{{ clientUrl }}icons/custom/{{ item.icon }}.svg" class="nav-link-icon" style="color: #fff"/>
    }
    <span class="nav-link-title">{{ item.title }}</span>
    @if (item.badge) {
      <span
        class="nav-link-badge"
        [ngStyle]="{
        'background-color': item.badge.bg,
        'color': item.badge.fg
      }"
      >
        {{ item.badge.title }}
      </span>
    }
  </ng-template>
}
