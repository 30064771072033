<omt-dialog-host [title]="'CUSTOMER.UPDATE_DOC' | translate" (closeClicked)="closeModal()">
  <div dialog-content fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="25px">
    <div fxLayout="column" fxFlex="100" fxLayoutGap="11px">
      <div fxLayout="column">
        <label>{{ 'CUSTOMER.FILE_NAME' | translate }}</label>
        <input class="redesign" [(ngModel)]="document.name" [placeholder]="'CUSTOMER.FILE_NAME' | translate"/>
      </div>
      <div fxLayout="column">
        <label>{{ 'CUSTOMER.DOC_DESCRIPTION' | translate }}</label>
        <textarea class="redesign" [(ngModel)]="document.description" [placeholder]="'CUSTOMER.DOC_DESCRIPTION' | translate"></textarea>
      </div>
    </div>
  </div>

  <div dialog-footer class="modal-actions">
    <button (click)="save()" mat-fab color="primary">
      <i class="fas fa-check fa-2x text-white"></i>
    </button>
  </div>
</omt-dialog-host>
