import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {DialogService} from '@core/services/global/dialog.service';
import {SubSink} from 'subsink';
import {CustomerModel} from '@customer/models';
import {CustomerService} from '@customer/customer.service';
import {UserSettingsService} from '@core/services/user-settings.service';
import {StorageKeyHelper} from '@core/models/storage-key-helper';
import {HeaderService} from '@core/components/header/header.service';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';

@Component({
    selector: 'omt-customer',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit, OnChanges, OnDestroy {
    private sort: MatSort;
    private paginator: MatPaginator;
    private subs = new SubSink();

    customers: CustomerModel[];
    isLoading = false;
    dataSource = new MatTableDataSource();
    displayedColumns = ['name', 'number', 'street', 'city', 'contact', 'category', 'status', 'board', 'actions'];
    searchFilter = '';


    constructor(
        private readonly customerService: CustomerService,
        private readonly dialogService: DialogService,
        private readonly userSettingsService: UserSettingsService,
        private readonly headerService: HeaderService
    ) {
    }

    @ViewChild(MatSort) set content(content: MatSort) {
        this.sort = content;
        if (this.sort) {
            this.dataSource.sort = this.sort;
        }

    }

    @ViewChild(MatPaginator) set matPaginator(matPaginator: MatPaginator) {
        this.paginator = matPaginator;
        if (this.paginator) {
            this.dataSource.paginator = this.paginator;
        }

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        this.headerService.setTitle('CUSTOMER.CUSTOMERS', true);
        this.headerService.slideInContent = true;
        this.loadCustomers();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.customers) {
            this.tableConfiguration();
        }

    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Load
    // -----------------------------------------------------------------------------------------------------

    loadCustomers(): void {
        this.isLoading = true;
        this.subs.sink = this.customerService.getCustomers()
            .pipe(catchError(() => of([])))
            .subscribe((data: CustomerModel[]) => {
                this.customers = data;
                this.isLoading = false;
                this.tableConfiguration();
                this.sort.sort({id: 'displayName', start: 'asc'} as MatSortable);
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Actions
    // -----------------------------------------------------------------------------------------------------

    tableConfiguration(): void {
        this.dataSource = new MatTableDataSource();
        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (item: CustomerModel, property: string) => {
            switch (property.toLocaleLowerCase()) {
                case 'number':
                    return item.customerKey?.toLocaleLowerCase();

                case 'contact':
                    return item.email?.toLocaleLowerCase();

                case 'board':
                    return item.openTickets;

                case 'category':
                    return item.category?.name;

                default:
                    return item[property]?.toLocaleLowerCase();
            }
        };

        this.dataSource.filterPredicate = (data: CustomerModel, filter) => (data.displayName != null && data.displayName.toLowerCase().indexOf(filter) !== -1) ||
            (data.customerKey != null && data.customerKey.toLowerCase().indexOf(filter) !== -1) ||
            (data.city != null && data.city.toLowerCase().indexOf(filter) !== -1) ||
            (data.street != null && data.street.toLowerCase().indexOf(filter) !== -1) ||
            (data.phone != null && data.phone.toLowerCase().indexOf(filter) !== -1) ||
            (data.email != null && data.email.toLowerCase().indexOf(filter) !== -1);

        this.dataSource.paginator = this.paginator;

        setTimeout(() => {
            this.dataSource.data = this.customers;
        }, 1);
    }

    filter(event: string): void {
        const newFilter = event?.toLowerCase() ?? '';
        this.searchFilter = newFilter;
        this.dataSource.filter = newFilter;
    }

    setPageSize(ev: any): void {
        const pageSize = ev.pageSize;
        this.userSettingsService.setPageSize(StorageKeyHelper.CUSTOMER_PAGE_SIZE_KEY, pageSize);
    }

    getPageSize(): number {
        return this.userSettingsService.getPageSize(StorageKeyHelper.CUSTOMER_PAGE_SIZE_KEY);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Dialog
    // -----------------------------------------------------------------------------------------------------

    openDialog(): void {
        const dialogRef = this.dialogService.openCustomerCreateDialog();

        this.subs.sink = dialogRef.componentInstance.newCustomer.subscribe(() => {
            this.loadCustomers();
        });
    }
}
