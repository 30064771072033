<mat-card class="vh-70" fxLayout="column" fxLayoutAlign="space-between">
  <!--  main row   -->
  <div id="contact" fxLayout="row" fxLayoutAlign="start" fxLayout.lt-lg="column">
    <!--    Customer image, number and status    -->
    <div fxLayout="column" fxFlex="20" fxFlex.lt-lg="100" fxLayoutAlign="start center">
      @if (!editMode) {
        <img [src]="customer.avatarUrl" class="media-object p-10 mb-2 cursor-pointer"/>
      }
      @if (editMode) {
        <omt-image-cropper
          class="img-dashed-border media-object w-250 p-10 mb-2"
          [image]="customer.avatarUrl"
          [width]="250"
          (imageSelected)="uploadImage($event)"
        ></omt-image-cropper>
      }
      <div class="mb-1"> {{ 'CUSTOMER.CUSTOMER_NO' | translate }}
        <omt-number-range-key [key]="customer.customerKey"></omt-number-range-key>
      </div>

      @if (customer.disabled) {
        <span> inaktiv</span>
      }

      @if (!customer.disabled) {
        <span> aktiv</span>
      }
    </div>

    <!--    contact    -->
    <div fxFlex="38" fxFlex.lt-lg="100">
      <div fxLayout="column" fxFlex="75" fxFlex.lt-lg="100" fxLayoutGap="10px">
        <span class="headline mb-3">{{ 'CUSTOMER.GENERAL_CONTACT_INFO' | translate }}</span>

        @if (!editMode) {
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <i class="fas fa-shapes"></i>
            @if (!editMode && customer.category) {
              <span>{{ customer.category.name }}</span>
            }
          </div>
        }

        @if (editMode) {
          <div fxLayout="column">
            <label>{{ 'CUSTOMER.CATEGORY' | translate }}</label>
            <omt-customer-category-dropdown [(category)]="customer.category"></omt-customer-category-dropdown>
          </div>
        }

        @if (!editMode) {
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <i class="fas fa-building"></i>
            <span>{{ customer.name | empty }}</span>
          </div>
        }

        @if (editMode) {
          <div fxLayout="column">
            <label>{{ 'CUSTOMER.COMPANY' | translate }}</label>
            <input class="redesign" [(ngModel)]="customer.name" type="text" [placeholder]="'CUSTOMER.COMPANY' | translate"/>
          </div>
        }

        @if (editMode) {
          <div fxLayout="column">
            <label>{{ 'CUSTOMER.SALUTATION' | translate }}</label>
            <ng-select class="redesign"
                       [items]="salutations"
                       bindLabel="name"
                       [(ngModel)]="customer.salutation"
                       [placeholder]="'CUSTOMER.SALUTATION' | translate"></ng-select>
          </div>
        }

        @if (!editMode) {
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <i class="fas fa-signature"></i>
            @if (customer.salutation) {
              <span>{{ customer.salutation.name }}</span>
            }
            <span>{{ customer.firstName }} {{ customer.lastName }}</span>
          </div>
        }

        @if (editMode) {
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxFlex="45">
              <label>{{ 'CUSTOMER.FIRST_NAME' | translate }}</label>
              <input class="redesign" type="text" [(ngModel)]="customer.firstName" (ngModelChange)="updateInvoice()"
                     [placeholder]="'CUSTOMER.FIRST_NAME' | translate"/>
            </div>
            <div fxLayout="column" fxFlex="45">
              <label>{{ 'CUSTOMER.LAST_NAME' | translate }}</label>
              <input class="redesign" type="text" [(ngModel)]="customer.lastName" (ngModelChange)="updateInvoice()" [placeholder]="'CUSTOMER.LAST_NAME' | translate"/>
            </div>
          </div>
        }

        @if (!editMode) {
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <span>{{ customer.affix }}</span>
          </div>
        }

        @if (editMode) {
          <div fxLayout="column">
            <label>{{ 'CUSTOMER.ADDITION' | translate }}</label>
            <input class="redesign" type="text" [(ngModel)]="customer.affix" [placeholder]="'CUSTOMER.ADDITION' | translate"/>
          </div>
        }

        @if (!editMode) {
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <i class="fas fa-map-marker-alt"></i>
            @if (customer.street && customer.city) {
              <a href="https://www.google.com/maps/search/?api=1&query={{ customer.city }} {{ customer.street }} {{ customer.houseNumber }}"
                 target="_blank">{{ customer.street }} {{ customer.houseNumber }}, {{ customer.postcode }} {{ customer.city }}, {{ customer.country }}
              </a>
            }
          </div>
        }

        @if (editMode) {
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxFlex="70">
              <label>{{ 'CUSTOMER.STREET' | translate }}</label>
              <input class="redesign" type="text" [(ngModel)]="customer.street" (ngModelChange)="updateInvoice()" [placeholder]="'CUSTOMER.STREET' | translate"/>
            </div>
            <div fxLayout="column" fxFlex="25">
              <label>{{ 'CUSTOMER.HOUSE_NUMBER' | translate }}</label>
              <input class="redesign"
                     type="text"
                     [(ngModel)]="customer.houseNumber"
                     (ngModelChange)="updateInvoice()"
                     [placeholder]="'CUSTOMER.HOUSE_NUMBER' | translate"/>
            </div>
          </div>
        }

        @if (editMode) {
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxFlex="25">
              <label>{{ 'CUSTOMER.POSTCODE' | translate }}</label>
              <input class="redesign" [(ngModel)]="customer.postcode" (ngModelChange)="updateInvoice()" type="text" [placeholder]="'CUSTOMER.POSTCODE' | translate"/>
            </div>
            <div fxLayout="column" fxFlex="70">
              <label>{{ 'CUSTOMER.LOCATION' | translate }}</label>
              <input class="redesign" [(ngModel)]="customer.city" (ngModelChange)="updateInvoice()" type="text" [placeholder]="'CUSTOMER.LOCATION' | translate"/>
            </div>
          </div>
        }

        @if (editMode) {
          <div fxLayout="column">
            <label>{{ 'CUSTOMER.COUNTRY' | translate }}</label>
            <input class="redesign" [(ngModel)]="customer.country" (ngModelChange)="updateInvoice()" type="text" [placeholder]="'CUSTOMER.COUNTRY' | translate"/>
          </div>
        }

        @if (!editMode) {
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <i class="fas fa-envelope"></i>
            <a href="mailto:{{ customer.email }}">{{ customer.email | empty }}</a>
          </div>
        }

        @if (editMode) {
          <div fxLayout="column">
            <label>{{ 'CUSTOMER.E_MAIL' | translate }}</label>
            <input class="redesign" [(ngModel)]="customer.email" type="text" [placeholder]="'CUSTOMER.E_MAIL' | translate"/>
          </div>
        }

        @if (!editMode) {
          <div
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="start center"
            fxLayoutAlign.lt-lg="start start"
            fxLayoutGap="25px"
            fxLayoutGap.lt-lg="15px">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
              <i class="fas fa-phone"></i>
              <a href="tel:{{ customer.phone }}">{{ customer.phone | empty }}</a>
              <span class="text-muted">({{ 'CUSTOMER.FOR_BUSINESS' | translate }})</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
              <i class="fas fa-phone"></i>
              <a href="tel:{{ customer.mobilePhone }}">{{ customer.mobilePhone | empty }}</a>
              <span class="text-muted">({{ 'CUSTOMER.MOBILE' | translate }})</span>
            </div>
          </div>
        }

        @if (editMode) {
          <div fxLayout="column">
            <label>{{ 'CUSTOMER.PHONE_BUSINESS' | translate }}</label>
            <input class="redesign" [(ngModel)]="customer.phone" type="text" [placeholder]="'CUSTOMER.PHONE_BUSINESS' | translate"/>
          </div>
        }

        @if (editMode) {
          <div fxLayout="column">
            <label>{{ 'CUSTOMER.PHONE_MOBILE' | translate }}</label>
            <input class="redesign" type="text" [(ngModel)]="customer.mobilePhone" [placeholder]="'CUSTOMER.PHONE_MOBILE' | translate"/>
          </div>
        }

        @if (!editMode) {
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <i class="fas fa-fax"></i>
            <span
            >{{ customer.fax | empty }}
              <span class="text-muted">{{ 'CUSTOMER.FAX' | translate }}</span>
            </span>
          </div>
        }

        @if (editMode) {
          <div fxLayout="column">
            <label>{{ 'CUSTOMER.FAX' | translate }}</label>
            <input class="redesign" [(ngModel)]="customer.fax" type="text" [placeholder]="'CUSTOMER.FAX' | translate"/>
          </div>
        }

        @if (!editMode) {
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <i class="fas fa-globe"></i>
            <a
              [href]="customer.webPage && (customer.webPage.startsWith('http://') || customer.webPage.startsWith('https://')) ? customer.webPage : '//' + customer.webPage"
              target="_blank"
            >{{ customer.webPage | empty }}
            </a
            >
          </div>
        }

        @if (editMode) {
          <div fxLayout="column">
            <label>{{ 'CUSTOMER.WEBSITE' | translate }}</label>
            <input class="redesign" [(ngModel)]="customer.webPage" type="text" [placeholder]="'CUSTOMER.WEBSITE' | translate"/>
          </div>
        }

        @if (!editMode) {
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <i class="fas fa-money-bill"></i>
            <span>{{ customer.taxNumber }}</span>
          </div>
        }

        @if (editMode) {
          <div fxLayout="column">
            <label>{{ 'CUSTOMER.TAX_ID_NO' | translate }}</label>
            <input class="redesign" type="text" [(ngModel)]="customer.taxNumber" [placeholder]="'CUSTOMER.TAX_ID_NO' | translate"/>
          </div>
        }

        @if (editMode) {
          <div fxLayout="column">
            <label>{{ 'CUSTOMER.MATCHCODE' | translate }}</label>
            <input class="redesign" type="text" [(ngModel)]="customer.matchCode" [placeholder]="'CUSTOMER.MATCHCODE' | translate"/>
          </div>
        }

        @if (!editMode) {
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <i class="fas fa-newspaper"></i>
            @if (customer.newsletter) {
              <span>{{ 'CUSTOMER.NEWSLETTER_SUBSCRIBED' | translate }}</span>
            }
            @if (!customer.newsletter) {
              <span>{{ 'CUSTOMER.NEWSLETTER_NOT_SUBSCRIBED' | translate }}</span>
            }
          </div>
        }

        @if (!editMode) {
          <span>
            {{ customer.matchCode }}
          </span>
        }

        @if (editMode) {
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-slide-toggle [(ngModel)]="customer.newsletter"></mat-slide-toggle>
            <span>{{ 'CUSTOMER.NEWSLETTER' | translate }}</span>
          </div>
        }

        @if (editMode) {
          <div class="mb-1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-slide-toggle [(ngModel)]="customer.disabled"></mat-slide-toggle>
            <span>{{ 'CUSTOMER.DEACTIVATED' | translate }}</span>
          </div>
        }
      </div>
    </div>

    <mat-divider vertical class="mr-5"></mat-divider>

    <!--   invoice     -->
    <div fxLayout="column" fxFlex="30" fxFlex.lt-lg="100" fxLayoutGap="10px">
      <span class="headline mb-3">
        {{ 'CUSTOMER.BILLING_ADDRESS' | translate }}
      </span>

      @if (editMode) {
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-slide-toggle [(ngModel)]="invoiceSameAddress" (ngModelChange)="setInvoiceToContactAddress()"></mat-slide-toggle>
          <span>{{ 'CUSTOMER.BILLING_ADDRESS_IS_THE_SAME' | translate }}</span>
        </div>
      }

      @if (editMode && invoiceSameAddress) {
        <div class="invoice-disabled mt-1" fxLayout="column" fxLayoutGap="15px">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="20px">
            <div fxLayout="column">
              <span class="text-muted">Name</span>
              <span>{{ customer.invoiceFirstName }} {{ customer.invoiceLastName }}</span>
            </div>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="20px">
            <div fxLayout="column" fxFlex="70">
              <span class="text-muted">{{ 'CUSTOMER.STREET' | translate }}</span>
              <span>{{ customer.invoiceStreet }}</span>
            </div>
            <div fxLayout="column" fxFlex="25">
              <span class="text-muted">{{ 'CUSTOMER.HOUSE_NUMBER' | translate }}</span>
              <span>{{ customer.invoiceHouseNumber }}</span>
            </div>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="20px">
            <div fxLayout="column" fxFlex="25">
              <span class="text-muted">{{ 'CUSTOMER.POSTCODE' | translate }}</span>
              <span>{{ customer.invoicePostcode }}</span>
            </div>
            <div fxLayout="column" fxFlex="70">
              <span class="text-muted">{{ 'CUSTOMER.LOCATION' | translate }}</span>
              <span>{{ customer.invoiceCity }}</span>
            </div>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="20px">
            <div fxLayout="column">
              <span class="text-muted">{{ 'CUSTOMER.COUNTRY' | translate }}</span>
              <span>{{ customer.invoiceCountry }}</span>
            </div>
          </div>
        </div>
      }

      @if (!editMode) {
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
          <i class="fas fa-signature"></i>
          <span>{{ customer.invoiceFirstName | empty }} {{ customer.invoiceLastName }}</span>
        </div>
      }

      @if (editMode && !invoiceSameAddress) {
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="column" fxFlex="45">
            <label>{{ 'CUSTOMER.FIRST_NAME' | translate }}</label>
            <input class="redesign" type="text" [(ngModel)]="customer.invoiceFirstName" [disabled]="invoiceSameAddress" [placeholder]="'CUSTOMER.FIRST_NAME' | translate"/>
          </div>
          <div fxLayout="column" fxFlex="45">
            <label>{{ 'CUSTOMER.LAST_NAME' | translate }}</label>
            <input class="redesign" type="text" [(ngModel)]="customer.invoiceLastName" [disabled]="invoiceSameAddress" [placeholder]="'CUSTOMER.LAST_NAME' | translate"/>
          </div>
        </div>
      }

      @if (!editMode) {
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
          <i class="fas fa-map-marker-alt"></i>
          <span>{{ invoiceAddressString }}</span>
        </div>
      }

      @if (editMode && !invoiceSameAddress) {
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="column" fxFlex="70">
            <label>{{ 'CUSTOMER.STREET' | translate }}</label>
            <input class="redesign" type="text" [(ngModel)]="customer.invoiceStreet" [disabled]="invoiceSameAddress" [placeholder]="'CUSTOMER.STREET' | translate"/>
          </div>
          <div fxLayout="column" fxFlex="25">
            <label>{{ 'CUSTOMER.HOUSE_NUMBER' | translate }}</label>
            <input class="redesign"
                   type="text"
                   [(ngModel)]="customer.invoiceHouseNumber"
                   [disabled]="invoiceSameAddress"
                   [placeholder]="'CUSTOMER.HOUSE_NUMBER' | translate"/>
          </div>
        </div>
      }

      @if (editMode && !invoiceSameAddress) {
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="column" fxFlex="25">
            <label>{{ 'CUSTOMER.POSTCODE' | translate }}</label>
            <input class="redesign" type="text" [(ngModel)]="customer.invoicePostcode" [disabled]="invoiceSameAddress" [placeholder]="'CUSTOMER.POSTCODE' | translate"/>
          </div>
          <div fxLayout="column" fxFlex="70">
            <label>{{ 'CUSTOMER.LOCATION' | translate }}</label>
            <input class="redesign" type="text" [(ngModel)]="customer.invoiceCity" [disabled]="invoiceSameAddress" [placeholder]="'CUSTOMER.LOCATION' | translate"/>
          </div>
        </div>
      }

      @if (editMode && !invoiceSameAddress) {
        <div fxLayout="column">
          <label>{{ 'CUSTOMER.COUNTRY' | translate }}</label>
          <input class="redesign" [(ngModel)]="customer.invoiceCountry" type="text" [disabled]="invoiceSameAddress" [placeholder]="'CUSTOMER.COUNTRY' | translate"/>
        </div>
      }

      <span class="headline mb-3 mt-5">
        {{ 'CUSTOMER.ADDITIONAL_DATA' | translate }}
      </span>

      <omt-form-input [(value)]="customer.numberOfEmployees" [editMode]="editMode" [label]="'CUSTOMER.NUMBER_OF_EMPLOYEES' | translate" type="number"></omt-form-input>
      <omt-form-input [(value)]="customer.numberOfComputerWorkplaces" [editMode]="editMode" [label]="'CUSTOMER.NUMBER_OF_PC_WORKPLACES' | translate" type="number"></omt-form-input>
      <omt-form-input [(value)]="customer.yearlyRevenue" [editMode]="editMode" [label]="'CUSTOMER.YEARLY_REVENUE' | translate" type="number"></omt-form-input>
      <omt-form-element [label]="'GLOBAL.SECTOR' | translate">
        @if (editMode) {
          <omt-sector-dropdown [(selectedSector)]="customer.sector"></omt-sector-dropdown>
        } @else {
          <span>{{ customer.sector?.name | empty }}</span>
        }
      </omt-form-element>
      @if (customer.sector) {
        <omt-form-element [label]="'GLOBAL.SUB_SECTOR' | translate">
          @if (editMode) {
            <omt-sub-sector-dropdown [(selectedSubSector)]="customer.subSector" [sectorId]="customer.sector.id"></omt-sub-sector-dropdown>
          } @else {
            <span>{{ customer.subSector?.name | empty }}</span>
          }
        </omt-form-element>
      }
    </div>
  </div>

  <div class="delete-section pt-3" fxLayout="row" fxLayoutAlign="center center">
    <button (click)="onDelete()" mat-button class="rounded delete w-250">{{ 'CUSTOMER.DELETE_CUSTOMER' | translate }}</button>
  </div>
</mat-card>
