export const locale = {
    lang: 'de',
    data: {
        NAV: {
            // ----------------------------------------------------------------------------------------------------- //
            // NAVIGATION
            // ----------------------------------------------------------------------------------------------------- //

            PRESENCE: 'Anwesenheit',
            BOOKING_CORRECTION: 'Buchungskorrektur',
            CALENDAR: 'Kalender',
            DAY_JOURNAL: 'Tagesjournal',
            MONTH_JOURNAL: 'Monatsjournal',
            CUSTOMER: 'Kunden',
            HUMAN_RESOURCE: 'Personalwesen',
            ABSENCE_REASON: 'Abwesenheitsgründe',
            WAREHOUSE: 'Warenwirtschaft',
            REPORTING: 'Berichte',
            MANAGEMENT: 'Verwaltung',
            SETTING: 'Einstellungen'
        }
    }
};
